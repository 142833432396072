<template>
  <div>
    <h1 class="text-center">
      Upload <span class="underline-text">Custom CSV</span>
    </h1>
    <div class="d-flex justify-center mt-10 pt-10">
      <div
        style="width: 700px; margin-bottom: 100px; border-radius: 10px"
        class="elevation-3 pa-5"
      >
        <v-text-field
          v-model="name"
          single-line
          outlined
          label="Custom Name"
        ></v-text-field>
        <div class="text-center pl-10 pr-10">
          <p>Upload CSV file</p>
          <p v-if="fileName"><v-icon style="color: #009688;">mdi-check-circle</v-icon> {{ fileName }}</p>
          <uploader @uploadSuccess="uploadSuccess"></uploader>
          <v-progress-circular
            indeterminate
            color="teal"
            v-if="loading"
          ></v-progress-circular>
          <v-btn outlined color="teal" large class="mt-5" v-else @click="uploadFile"
            >Create A New Account</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Uploader from "../components/Uploader.vue";
import Papa from "papaparse";
import Axios from 'axios'
export default {
  components: { Uploader },
  props: {
    account: "",
  },
  data() {
    return {
      file: "",
      fileName : "",
      parsed: false,
      content: [],
      processedContent: [],
      content_ready: false,
      coinBalances: [],
      subtractionHistory: [],
      loading: false,
    };
  },
  methods: {
    connect() {
      this.$router.push({
        name: "ConnectExchangeAPI",
        params: { exchange: this.$route.params.exchange },
      });
    },
    parseDate(dateString) {
      const [day, month, year, hour, minute] = dateString.split(/[\/\s:]/);
      return new Date(`20${year}`, month - 1, day, hour, minute);
    },
    uploadSuccess(value) {
      this.loading = true;
      this.file = value;
      this.fileName = this.file.name;
      this.loading = false;
    },
    async uploadFile() {
      var context = this
      this.loading = true
      var url = ""
      
        url = "http://localhost:5000/api/v1/csv-scan/custom"
      

      let formData = new FormData();
      formData.append("files", this.file)
      Axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          console.log("progress" + progressEvent.loaded);
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        }.bind(this),
      })
        .then(function (response) {
          context.loading = false
          context.$store.dispatch("SET_TRANSACTIONS", response.data.transactions);
          context.$store.dispatch("SET_PNL",response.data.pnlRecords)
          context.$store.dispatch("SET_COIN_BALANCE", response.data.finalBalances);
          console.log("SUCCESS!!");
        })
        .catch(function (e) {
          console.log(e)
          console.log("FAILURE!!");
        });
      // await Papa.parse(this.file, {
      //   header: true,
      //   skipEmptyLines: true,
      //   complete: function (results) {
      //     this.content = results.data;
      //     this.parsed = true;
      //     this.processContent();
      //   }.bind(this),
      // });
    },
    // processContent() {
    //   if (this.account.title == "Bitbns") {
    //     for (var i = 0; i < this.content.length; i++) {
    //       let _side = this.content[i]["SIDE"];
    //       let _timestamp = this.content[i]["Time"];
    //       let _ts = _timestamp.split(" ");
    //       let _date = _ts[0];
    //       let _time = _ts[1];
    //       let _item = {
    //         timestamp: _timestamp,
    //         date: _date,
    //         time: _time,
    //         trade_pair: "",
    //         type: _side,
    //         desc: this.content[i]["Desc"],
    //         in_coin: "",
    //         in_quantity: "",
    //         out_coin: "",
    //         out_quantity: "",
    //         exchange: "Bitbns",
    //         fee: this.content[i]["Fee"],
    //         fee_coin: "",
    //         tds_amount: "",
    //         tds_coin: "",
    //         in_cost_basis: "",
    //         out_cost_basis: "",
    //         usd_in: "",
    //         usd_out: "",
    //       };
    //       if (_side.toLowerCase() == "buy") {
    //         _item.in_coin = this.content[i]["Coin"];
    //         _item.in_quantity = this.content[i]["Crypto_Amt"];
    //         _item.in_cost_basis = this.content[i]["Rate"];
    //         _item.usd_in = this.content[i]["FIAT"];
    //       } else if (_side.toLowerCase() == "sell") {
    //         _item.out_coin = this.content[i]["Coin"];
    //         _item.out_quantity = this.content[i]["Crypto_Amt"];
    //         _item.out_cost_basis = this.content[i]["Rate"];
    //         _item.usd_out = this.content[i]["FIAT"];
    //       }
    //       this.processedContent.push(_item);
    //     }
    //     this.$store.dispatch("SET_TRANSACTIONS", this.processedContent);

    //     this.runCalculations();
    //   }
    // },
    // runCalculations() {
    //   var in_code = this.processedContent.filter(
    //     (item) => item.type.toUpperCase() === "BUY"
    //   );
    //   var out_code = this.processedContent.filter(
    //     (item) => item.type.toUpperCase() === "SELL"
    //   );

    //   in_code.sort((a, b) => {
    //     if (a.in_coin !== b.in_coin) {
    //       return a.in_coin.localeCompare(b.in_coin);
    //     } else {
    //       return new Date(a.timestamp) - new Date(b.timestamp);
    //     }
    //   });
    //   in_code = in_code.map((obj) => ({
    //     date: obj.date,
    //     time: obj.time,
    //     coin: obj.in_coin,
    //     quantity: obj.in_quantity,
    //     cost_basis: obj.in_cost_basis,
    //     exchange: obj.exchange,
    //   }));
    //   out_code.sort((a, b) => {
    //     if (a.out_coin !== b.out_coin) {
    //       return a.out_coin.localeCompare(b.out_coin);
    //     } else {
    //       return new Date(a.timestamp) - new Date(b.timestamp);
    //     }
    //   });
    //   out_code = out_code.map((obj) => ({
    //     date: obj.date,
    //     time: obj.time,
    //     coin: obj.out_coin,
    //     quantity: obj.out_quantity,
    //     cost_basis: obj.out_cost_basis,
    //     exchange: obj.exchange,
    //   }));
    //   console.log(JSON.stringify(in_code));
    //   console.log(JSON.stringify(out_code));
    //   this.calculateHIFO(in_code, out_code);
    // },
    // calculateHIFO(in_code, out_code) {
    //   // Initialize the result arrays
    //   const coin_balances = {};
    //   const subtraction_history = [];

    //   // Helper function to find the suitable buy for a sell
    //   function findSuitableBuy(sell) {
    //     return in_code
    //       .filter(
    //         (buy) =>
    //           buy.coin === sell.coin &&
    //           buy.date < sell.date &&
    //           (buy.date !== sell.date || buy.time <= sell.time)
    //       )
    //       .sort((a, b) => b.cost_basis - a.cost_basis);
    //   }

    //   // Loop through the sells (out_code)
    //   for (const sell of out_code) {
    //     let remainingQuantity = sell.quantity;

    //     // Find the suitable buys for this sell
    //     const suitableBuys = findSuitableBuy(sell);

    //     // Loop through the suitable buys and perform subtraction
    //     for (const buy of suitableBuys) {
    //       if (remainingQuantity > 0 && buy.quantity > 0) {
    //         const subtractedQuantity = Math.min(
    //           remainingQuantity,
    //           buy.quantity
    //         );

    //         // Update remaining quantity in the buy and sell objects
    //         buy.quantity -= subtractedQuantity;
    //         remainingQuantity -= subtractedQuantity;

    //         // Create a subtraction_history object
    //         const historyEntry = {
    //           coin: sell.coin,
    //           originalQuantity: buy.quantity + subtractedQuantity,
    //           subtractedQuantity,
    //           remainingQuantity: buy.quantity,
    //           costBasis: buy.cost_basis,
    //           buyDate: buy.date,
    //           buyTime: buy.time,
    //           sellDate: sell.date,
    //           sellTime: sell.time,
    //         };
    //         subtraction_history.push(historyEntry);
    //       }
    //     }
    //   }

    //   // Calculate coin balances
    //   for (const buy of in_code) {
    //     if (!coin_balances[buy.coin]) {
    //       coin_balances[buy.coin] = 0;
    //     }
    //     coin_balances[buy.coin] += parseFloat(buy.quantity);
    //   }

    //   this.subtractionHistory = subtraction_history;
    //   this.coinBalances = Object.keys(coin_balances).map((coin) => {
    //     return { coin: coin, quantity: coin_balances[coin] };
    //   });
    //   this.$store.dispatch("SET_COIN_BALANCE", this.coinBalances);
    //   console.log("BALANCE" + JSON.stringify(this.coinBalances));
    //   setTimeout(() => {
    //     this.loading = false;
    //     this.$toastr.s(
    //       "Data successfully extracted and synced from the CSV file.",
    //       "Data Synced"
    //     );
    //   }, 3000);
    // },
  },
};
</script>

  <style scoped>
.underline-text {
  position: relative;
  display: inline-block;
  color: #4aabab;
}
.underline-text::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -20px;
  right: 0;
  stroke: 3px;
  width: 230px;
  height: 25px;
  background-image: url("../assets/welcome.png");
  /* filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25)); */
  background-repeat: no-repeat;
  background-size: cover;
}
</style>